.hotspots {
	display: flex;
	flex-wrap: wrap;
	padding: 0.5rem;
	justify-content: flex-start;
	align-items: flex-start;
}

@media (--mq-from-small) {
	.hotspots {
		padding: 1rem;
	}
}

.hotspots__hotspot {
	box-sizing: border-box;
	cursor: pointer;
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 1;
	overflow: hidden;
	padding: 0.5rem;
}

@media (--mq-from-small) {
	.hotspots__hotspot {
		padding: 1rem;
	}
}

@media (--mq-from-wide) {
	.hotspots__hotspot {
		flex-basis: 33.33%;
	}
}

.hotspots__hotspot--disabled {
	cursor: auto;
	opacity: 0.15;
}

.hotspot {
	font-size: 0.875rem;
	line-height: 1.45;
}

.hotspot__title-wrapper {
	display: flex;
}

.hotspot__title {
	font-size: 1rem;
	line-height: 2rem;
	margin-top: 0;
	margin-bottom: 0.375em;
}

.hotspot__number {
	background-color: var(--color-hotspots-filter);
	border-radius: 50%;
	color: white;
	display: inline-block;
	font-size: 0.6875rem;
	letter-spacing: 0.075em;
	line-height: 1.5rem;
	margin-left: 1rem;
	position: relative;
	text-align: center;
	top: 3px;
	vertical-align: middle;
	width: 1.5rem;
	height: 1.5rem;
}

[data-type="fun"] .hotspot__number {
	background-color: var(--color-hotspots-filter-fun);
}

[data-type="food"] .hotspot__number {
	background-color: var(--color-hotspots-filter-food);
}

[data-type="drinks"] .hotspot__number {
	background-color: var(--color-hotspots-filter-drinks);
	border: 1px solid var(--color-red);
}

[data-type="hotel"] .hotspot__number {
	background-color: var(--color-hotspots-filter-hotel);
}

[data-type="sight"] .hotspot__number {
	background-color: var(--color-hotspots-filter-sight);
}

.hotspot__description p {
	margin-bottom: 0.375rem;
}

.hotspot__description p:first-child {
	margin-top: 0;
}

.hotspot__address {
	color: currentcolor;
}

.hotspot__footer {
	list-style: none;
	margin: 0.375rem -0.25rem;
	padding: 0;
}

.hotspot__footer li {
	display: inline-block;
}

.hotspot__footer a {
	color: currentcolor;
	padding: 0.25rem;
}
