.video {
	display: block;
	margin: 0 auto;
	overflow: hidden;
	position: relative;
	max-width: 1024px;
}

.video--full-width {
	width: 100%;
	max-width: none;
}

.video__poster {
	display: block;
	width: 100%;
	max-width: 100%;
}

.video.is-playing .video__poster {
	opacity: 0;
}

.video__label {
	color: var(--color-video-label);
	display: block;
	font-size: 1rem;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: 50%;
	right: 0;
	left: 0;
	transform: translateY(-50%);
	z-index: 2;
}

@media (--mq-from-small) {
	.video__label {
		font-size: 1.875rem;
	}
}

.video__label:hover {
	color: var(--color-video-label) !important;
}

.video.is-playing .video__label {
	display: none;
}

.video__embed {
	display: block;
	overflow: hidden;
	padding-top: 56.25%;
	position: relative;
	width: 100%;
}

.video__embed iframe,
.video__embed video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video__teaser {
	position: absolute;
	top: 0;
	left: 0;
	transition: 256ms opacity 112ms linear;
	z-index: 1;
}

.video.is-playing .video__teaser {
	opacity: 0;
}

.video__inline {
	width: 100%;
}

.video__inline video {
	width: 100%;
	height: auto;
}

.video__inline mr-play-pause-toggle {
	display: block;
	margin: 0;
	padding: 0;
	position: relative;
}

.video__inline mr-play-pause-toggle::after {
	background-color: var(--color-black);
	background-image: url("../images/play.svg");
	background-position: center center;
	background-repeat: no-repeat;
	background-size: 10px;
	content: "";
	cursor: pointer;
	display: block;
	padding: 10px;
	position: absolute;
	right: 20px;
	bottom: 20px;
	width: 10px;
	height: 10px;
	z-index: 1000;
}

.video__inline mr-play-pause-toggle[data-playing]::after {
	background-image: url("../images/pause.svg");
}
