.work-with-us {
	box-sizing: border-box;
	display: grid;
	grid-auto-rows: 1fr;
	grid-template-columns: 1fr 1fr;
	padding-right: 1rem;
	padding-left: 1rem;
}

@media (--mq-from-small) {
	.work-with-us {
		padding-right: 2rem;
		padding-left: 2rem;
	}
}

@media (min-width: 464px) {
	.work-with-us {
		/* 144 * 3 + (16 * 2) */
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (min-width: 608px) {
	.work-with-us {
		/* 144 * 4 + (16 * 2) */
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

@media (--mq-from-medium) {
	.work-with-us {
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	}
}

.work-with-us::before {
	content: "";
	grid-row: 1 / 1;
	grid-column: 1 / 1;
	padding-bottom: 100%;
	width: 0;
}

.work-with-us__item {
	color: var(--t-color);
	display: flex;
}

.work-with-us__item:first-child {
	grid-row: 1 / 1;
	grid-column: 1 / 1;
}

@media (max-width: 463px) {
	.work-with-us__item {
		/* in a row of 2 columns */
	}

	.work-with-us__item:nth-child(4n-3),
	.work-with-us__item:nth-child(4n) {
		background-color: var(--t-color);
		color: var(--t-background-color);
	}
}

@media (min-width: 608px) and (--mq-up-to-medium) {
	.work-with-us__item {
		/* in a row of 4 columns */
	}

	.work-with-us__item:nth-child(8n-7),
	.work-with-us__item:nth-child(8n-5),
	.work-with-us__item:nth-child(8n-2),
	.work-with-us__item:nth-child(8n) {
		background-color: var(--t-color);
		color: var(--t-background-color);
	}
}

@media (min-width: 464px) and (max-width: 607px), (--mq-from-medium) {
	.work-with-us__item {
		/* in a row of 3 or 5 */
	}

	.work-with-us__item:nth-child(odd) {
		background-color: var(--t-color);
		color: var(--t-background-color);
	}
}

.work-with-us__item__link {
	box-sizing: border-box;
	color: currentColor;
	cursor: pointer;
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	padding: 0.625rem;
	justify-content: space-between;
	text-align: center;
	text-decoration: none;
	min-height: 100%;
}

.work-with-us__item__link:focus,
.work-with-us__item__link:hover {
	color: currentColor !important;
}

@media (--mq-from-small) {
	.work-with-us__item__link {
		padding: 1.25rem;
	}
}

@media (--mq-from-wide) {
	.work-with-us__item__link {
		padding: 1.875rem;
	}
}

.work-with-us__item__location,
.work-with-us__item__company,
.work-with-us__item__title {
	display: block;
	flex-grow: 0;
	flex-shrink: 0;
}

.work-with-us__item__location,
.work-with-us__item__title {
	font-size: 0.625rem;
}

@media (--mq-from-small) {
	.work-with-us__item__location,
	.work-with-us__item__title {
		font-size: 0.75rem;
	}
}

@media (--mq-from-wide) {
	.work-with-us__item__location,
	.work-with-us__item__title {
		font-size: 0.9375rem;
	}
}

.work-with-us__item__company {
	font-size: 1.25rem;
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
}

@media (--mq-from-small) {
	.work-with-us__item__company {
		font-size: 1.625rem;
	}
}

@media (--mq-from-wide) {
	.work-with-us__item__company {
		font-size: 2rem;
	}
}

.work-with-us__item__link:focus .work-with-us__item__company,
.work-with-us__item__link:hover .work-with-us__item__company {
	text-decoration: underline;
}
