.t-red-black {
	--t-background-color: var(--color-black);
	--t-border-color: var(--color-red);
	--t-color: var(--color-red);
	--t-link-color: var(--color-red);
	--t-link-hover-color: var(--color-white);
	--t-text-contrast-color: var(--color-black);
	--t-link-active-nav-link: var(--color-white);
	--color-flicker-bg-startend: var(--color-red);
	--color-flicker-fg-startend: var(--color-black);
	--color-flicker-bg-middle: transparent;
	--color-flicker-fg-middle: currentcolor;
	--color-ticket-fg: var(--color-red);
	--color-ticket-bg: var(--color-white);
	--color-ticket-inner-border: var(--color-red);
	--color-ticket-inverted-fg: var(--color-red);
	--color-ticket-inverted-bg: var(--color-black);
	--color-ticket-inner-border-inverted: var(--color-red);
	--color-schedule-item-bg: var(--color-red);
	--color-schedule-item-fg: var(--color-white);
	--color-schedule-item-hover-bg: var(--color-white);
	--color-schedule-item-hover-fg: var(--color-red);
	--color-schedule-item-inverted-bg: var(--color-white);
	--color-schedule-item-inverted-fg: var(--color-red);
	--color-schedule-item-inverted-hover-bg: var(--color-red);
	--color-schedule-item-inverted-hover-fg: var(--color-white);

	/* Primary content color */
	color: var(--t-color);
}

/* Header - Page Top stuff */
.t-red-black .program__navigation,
.t-red-black .schedule__item--heading,
.t-red-black .site-header {
	background-color: var(--t-background-color);
}
/* Navigation Active */
.t-red-black .navigation__item.current-menu-item::after,
.t-red-black .navigation__item.current-page-ancestor::after {
	background-color: var(--t-background-color);
}
/* Wrappers */
.t-red-black .wrapper {
	background-color: var(--t-background-color);
}
/* Links - Navigation - Active */
.t-red-black .navigation__item.current-menu-item a,
.t-red-black .navigation__item.current-page-ancestor a,
.t-red-black .program__navigation .navigation__item.is-active a {
	color: var(--t-link-active-nav-link);
}
/* Links - Content */
.t-red-black .page-content a:not([class]) {
	color: var(--t-link-color);
}

.t-red-black .page-content a:not([class]):hover {
	color: var(--t-link-hover-color);
}
/* Links - Lineup */
.t-red-black .lineup__item a {
	color: var(--t-link-color);
}

.t-red-black .lineup__item a:hover {
	color: var(--t-link-hover-color);
}
/* Links - Hover */
.t-red-black a:hover {
	/* TODO : validate this */
	color: var(--t-link-hover-color);
}
/* Speaker - Links - Turn off Hover */
.t-red-black .speaker a:hover {
	color: var(--color-black);
}
/* Site Header CTA */
.t-red-black .site-header__cta {
	background-color: var(--t-background-color);
	color: var(--t-color);
}

.t-red-black .site-header__cta span {
	border-bottom-color: var(--t-color);
}

@media (--mq-up-to-small) {
	.t-red-black .site-header__cta span {
		background-color: var(--t-color);
		color: var(--t-background-color);
	}
}
/* Stage */
.t-red-black .program__stage__title {
	background-color: var(--t-color);
	color: var(--t-text-contrast-color);
}
/* Lineup Poster Image */
.t-red-black .lineup__poster-image {
	background-color: var(--t-background-color);
}

.t-red-black .lineup__poster-image::before,
.t-red-black .lineup__poster-image::after {
	border-color: var(--t-border-color);
}

.t-red-black .lineup__poster-image:hover::before,
.t-red-black .lineup__poster-image:hover::after {
	background-color: var(--t-background-color);
	border-color: var(--t-border-color);
}
/* Schedule */
.t-red-black .schedule__item--heading {
	color: var(--t-color);
}
