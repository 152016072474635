.overlay {
	background-color: rgb(0 0 0 / 0.5);
	cursor: auto;
	display: block;
	bottom: 0;
	left: 0;
	min-height: 100%;
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: var(--z-index-overlay);
}

@media (--mq-from-small) {
	.overlay {
		background-color: rgb(0 0 0 / 0.15);
	}
}

.is-showing-popup .overlay,
.overlay.is-hidden {
	display: none;
}

.overlay__overlay {
	background-color: white;
	color: black;
	margin: 1rem auto;
	overflow: hidden;
	position: relative;
	width: calc(100% - 2rem);
}

@media (--mq-from-small) {
	.overlay__overlay {
		margin-top: 7rem;
		margin-bottom: 7rem;
		width: 92.5%;
		max-width: 1280px;
	}
}

@media (--mq-from-medium) {
	.overlay__overlay {
		width: 82.5%;
	}
}

.overlay__close {
	color: currentcolor;
	position: absolute;
	text-decoration: none;
	top: 0;
	right: 0;
	width: 3rem;
	height: 3rem;
}

@media (--mq-up-to-small) {
	.overlay__close {
		position: fixed;
		top: 1rem;
		right: 1rem;
	}
}

@media (--mq-from-small) {
	.overlay__close {
		width: 6rem;
		height: 6rem;
	}
}

.overlay__close span {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1rem;
	height: 1rem;
}

@media (--mq-from-small) {
	.overlay__close span {
		width: 2rem;
		height: 2rem;
	}
}

.overlay__close svg {
	display: block;
	width: 100%;
	height: 100%;
}
