.page-intro {
	padding: 1rem;
}

@media (--mq-from-small) {
	.page-intro {
		display: flex;
		padding: 2rem;
	}
}

.l-split .page-intro {
	display: flex;
	flex-direction: column-reverse;
}

@media (--mq-from-small) {
	.l-split .page-intro {
		display: block; /* Don't render horizontally inside a split layout */
	}
}

.page-intro__title {
	box-sizing: border-box;
	flex-basis: 50%;
	font-size: 1.375rem;
	font-weight: 600;
	line-height: 1.35;
	margin: 0;
}

@media (--mq-from-xsmall) {
	.page-intro__title {
		font-size: 1.625rem;
		line-height: 1.1;
	}
}

@media (--mq-from-small) {
	.page-intro__title {
		font-size: 2.5rem;
		font-weight: 400;
	}

	.page-intro__title:first-child:not(:last-child) {
		padding-right: 1rem;
	}
}

.page-intro__content + .page-intro__title {
	margin-bottom: 0.5rem;
}

@media (--mq-from-small) {
	.page-intro__content + .page-intro__title {
		margin-top: 0.75em;
		margin-bottom: 0;
		margin-left: -4px;
	}
}

@media (--mq-from-wide) {
	.page-intro__title {
		font-size: 4rem;
	}
}

.page-intro__content {
	box-sizing: border-box;
	font-size: 1rem;
	line-height: 1.5;
}

@media (--mq-from-small) {
	.page-intro__content {
		font-size: 1.375rem;
	}
}

@media (--mq-from-medium) {
	.page-intro__content {
		font-size: 1.5rem;
	}
}

@media (--mq-from-wide) {
	.page-intro__content {
		font-size: 1.8rem;
		line-height: 1.4;
	}
}

.page-intro__title + .page-intro__content {
	flex-basis: 50%;
	padding-top: 0.5rem;
}

@media (--mq-from-small) {
	.page-intro__title + .page-intro__content {
		padding-top: 0.25rem;
		padding-left: 1rem;
	}
}

@media (--mq-from-medium) {
	.page-intro__title + .page-intro__content {
		padding-top: 0.5rem;
	}
}

@media (--mq-from-wide) {
	.page-intro__title + .page-intro__content {
		padding-top: 0.925rem;
	}
}

.page-intro__content p {
	margin-top: 0;
}

.page-intro__content p:last-child {
	margin-bottom: 0;
}
