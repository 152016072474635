.page-content {
	padding: 1rem;
}

@media (--mq-from-small) {
	.page-content {
		padding: 2rem;
	}
}

.page-content__row {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1rem;
	justify-content: flex-start;
	align-items: stretch;
}

@media (--mq-from-small) {
	.page-content__row {
		margin-bottom: 2rem;
	}
}

.page-content__row:last-child {
	margin-bottom: 0;
}

.page-content__row--has-border {
	border-bottom: 1px solid currentcolor;
	margin-right: -1rem;
	margin-left: -1rem;
	padding: 0 1rem 1rem;
}

@media (--mq-from-small) {
	.page-content__row--has-border {
		margin-right: -2rem;
		margin-left: -2rem;
		padding: 0 2rem 2rem;
	}
}

.page-content__row--is-full-width {
	margin-right: -1rem;
	margin-left: -1rem;
}

.page-content__row--is-full-width.page-content__row--has-border {
	padding-right: 0;
	padding-left: 0;
}

@media (--mq-from-small) {
	.page-content__row--is-full-width {
		margin-right: -2rem;
		margin-left: -2rem;
	}
}

.page-content__row--is-full-width:first-child {
	margin-top: -1rem;
}

@media (--mq-from-small) {
	.page-content__row--is-full-width:first-child {
		margin-top: -2rem;
	}
}

.page-content__row--is-full-width:last-child {
	margin-bottom: -1rem;
}

@media (--mq-from-small) {
	.page-content__row--is-full-width:last-child {
		margin-bottom: -2rem;
	}
}

.page-content__image {
	flex-basis: 100%;
	margin-bottom: 0.5rem;
}

@media (--mq-from-xsmall) {
	.page-content__image {
		box-sizing: border-box;
		flex-basis: 50%;
		margin-bottom: 0;
		padding-right: 1rem;
	}
}

@media (--mq-from-medium) {
	.page-content__image {
		flex-basis: 33.3333%;
	}
}

@media (--mq-from-wide) {
	.page-content__image {
		flex-basis: 25%;
	}
}

.page-content__image img {
	display: block;
	width: 100%;
}

.page-content__image--hover {
	position: relative;
}

.page-content__image--hover img {
	position: relative;
	z-index: 10;
}

.page-content__image--hover img:last-child {
	opacity: 0;
	position: absolute;
	top: 0;
	right: 1rem;
	left: 0;
	width: 100%;
	z-index: 1;
}

@media (--mq-from-xsmall) {
	.page-content__image--hover img:last-child {
		width: calc(100% - 1rem);
	}
}

.page-content__row:hover .page-content__image--hover img:last-child {
	opacity: 1;
}

.page-content__iframe {
	display: block;
	position: relative;
	width: 100%;
	height: 100%;
}

.page-content__iframe::before {
	content: "";
	display: block;
	padding-top: 130%;
}

/* mq-from-xsmall */
@media (--mq-from-xsmall) {
	.page-content__iframe::before {
		content: "";
		display: block;
		padding-top: 56.25%; /* 9 / 16 */
	}
}

.page-content__iframe > iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.page-content__title {
	box-sizing: border-box;
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 1.375rem;
	font-weight: 600;
	line-height: 1.1;
	margin-top: 0;
	margin-bottom: 0.5rem;
}

@media (--mq-from-xsmall) {
	.page-content__title {
		flex-basis: 33.3334%;
		font-size: 1.75rem;
		font-weight: 400;
		margin-bottom: 0;
		padding-right: 1rem;
	}
}

@media (--mq-from-medium) {
	.page-content__title {
		font-size: 2.25rem;
	}
}

@media (--mq-from-large) {
	.page-content__title {
		font-size: 3rem;
	}
}

@media (--mq-from-xsmall) {
	.page-content__image ~ .page-content__title {
		font-weight: 400;
		padding-left: 1rem;
		align-self: flex-end;
	}
}

@media (--mq-from-wide) {
	.page-content__image ~ .page-content__title {
		flex-basis: 25%;
		align-self: flex-start;
	}
}

.page-content__content {
	box-sizing: border-box;
	flex-basis: 100%;
	font-size: 1rem;
}

@media (--mq-from-small) {
	.page-content__content {
		font-size: 1.125rem;
	}
}

@media (--mq-from-medium) {
	.page-content__content {
		font-size: 1.375rem;
	}
}

@media (--mq-from-small) {
	.page-content__title + .page-content__content {
		flex-basis: 66.6667%;
		flex-grow: 0;
		flex-shrink: 0;
		padding-left: 1rem;
	}
}

@media (--mq-from-medium) {
	.page-content__title + .page-content__content {
		flex-basis: 50%;
	}
}

@media (--mq-from-small) {
	.page-content__image ~ .page-content__content {
		flex-basis: 100%;
		padding-top: 1rem;
		padding-left: 0;
	}
}

@media (--mq-from-medium) {
	.page-content__image ~ .page-content__content {
		flex-basis: 100%;
	}
}

@media (--mq-from-wide) {
	.page-content__image ~ .page-content__content {
		flex-basis: 50%;
		padding-top: 0;
	}
}

@media (--mq-from-wide) {
	.page-content__image ~ .page-content__subtitle ~ .page-content__content {
		flex-basis: calc(50% - 3.125rem);
	}
}

.page-content__content p {
	line-height: 1.5;
	margin-top: 0;
}

.page-content__content p:last-child {
	margin-bottom: 0;
}

.page-content__content a {
	text-decoration-skip-ink: auto;
}

.page-content__content img {
	display: block;
	margin-bottom: 1rem;
	width: 100%;
}

@media (--mq-from-small) {
	.page-content__content img {
		margin-bottom: 2rem;
	}
}

.page-content__content img:last-child {
	margin-bottom: 0;
}

.page-content__content--large {
	font-size: 1.875rem;
}

@media (--mq-from-small) {
	.page-content__content--large {
		font-size: 2.25rem;
	}
}

@media (--mq-from-large) {
	.page-content__content--large {
		font-size: 3rem;
	}
}

.page-content__content--large p {
	line-height: 1.2;
}

.page-content__content--has-footer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.page-content__content-footer {
	margin-top: 1rem;
	margin-bottom: -5px;
}

/* Align with image */

.page-content__content-footer p:last-child {
	margin-bottom: 0;
}

.page-content__content-footer a {
	color: currentcolor;
	text-decoration: none;
}

.page-content__subtitle {
	display: block;
	font-size: 1.125rem;
	margin: 0 0 0.5rem;
}

@media (--mq-from-xsmall) {
	.page-content__subtitle {
		margin: 1rem 0;
	}
}

@media (--mq-from-small) {
	.page-content__subtitle {
		margin-bottom: 0;
	}
}

@media (--mq-from-medium) and (--mq-up-to-wide) {
	.page-content__subtitle {
		flex-grow: 1;
		margin: 0;
		align-self: flex-end;
		text-align: right;
	}
}

@media (--mq-from-wide) {
	.page-content__subtitle {
		border-right: 1px solid white;
		box-sizing: border-box;
		flex-basis: 3.125rem;
		font-size: 1.125rem;
		margin: -2rem 2rem -2rem -2rem;
		order: -1;
		position: relative;
	}
}

@media (--mq-from-wide) {
	.page-content__subtitle span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-51.5%, -50%) rotate(-90deg);
		white-space: nowrap;
	}
}
