@keyframes color-flicker {
	0% {
		background-color: var(--color-flicker-bg-startend);
		color: var(--color-flicker-fg-startend);
	}

	50% {
		background-color: var(--color-flicker-bg-middle);
		color: var(--color-flicker-fg-middle);
	}

	100% {
		background-color: var(--color-flicker-bg-startend);
		color: var(--color-flicker-fg-startend);
	}
}

@keyframes opacity-flicker {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.site-header {
	background-color: var(--color-grey);
}

@media (--mq-up-to-small) {
	.site-header {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 1000;
	}
}

.site-header__branding {
	border-top: 1px solid currentcolor;
	border-bottom: 1px solid currentcolor;
	clear: both;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}

@media (--mq-from-small) {
	.site-header__branding {
		border-top: none;
		flex-wrap: nowrap;
	}
}

.site-header__title {
	flex-basis: 50%;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1;
	margin: 0;
	text-align: left;
	text-transform: uppercase;
}

@media (--mq-from-small) {
	.site-header__title {
		flex-basis: 0;
		font-size: 1.875rem;
	}
}

.site-header__title a {
	color: currentcolor;
	display: block;
	line-height: 2rem;
	padding: 0 1rem;
	text-decoration: none;
	white-space: nowrap;
	height: 2rem;
}

@media (--mq-from-small) {
	.site-header__title a {
		line-height: 1;
		padding: 0.525rem 0.5rem 0.625rem;
		height: auto;
	}
}

.site-header__tagline {
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 1rem;
	line-height: 1;
	position: relative;
	text-align: center;
}

@media (--mq-from-small) {
	.site-header__tagline {
		flex-basis: 0;
		font-size: 1.875rem;
	}
}

.site-header__tagline span {
	animation: opacity-flicker 6s steps(1, end) infinite normal;
	display: block;
	padding: 0.325rem 0.5rem 0.525rem;
}

@media (--mq-up-to-small) {
	.site-header__tagline span {
		line-height: 2rem;
		padding: 0 1rem 0 0;
		text-align: right;
		height: 2rem;
	}
}

@media (--mq-from-small) {
	.site-header__tagline span {
		padding: 0.525rem 0.5rem 0.625rem;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.site-header__tagline span {
		animation: none;
	}
}

.site-header__tagline span:last-child {
	animation-direction: reverse;
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}

.site-header__secondary-navigation {
	display: none;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
}

@media (--mq-from-small) {
	.site-header__secondary-navigation {
		display: block;
	}
}

.site-header__socials {
	border-left: 1px solid currentcolor;
	display: none;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 0;
	align-items: center; /* Center only child element */
	align-self: stretch; /* Stretch to make border fill available space */
}

@media (--mq-from-small) {
	.site-header__socials {
		display: flex;
	}
}

.site-header__menu-toggle {
	appearance: none;
	background-color: white;
	border: none;
	border-bottom-color: white;
	color: currentColor;
	display: none;
	float: right;
	font-size: 1rem;
	padding: 9px 1rem 6px;
	text-align: right;
	text-decoration: none;
	text-transform: uppercase;
	width: 50%;
}

.site-header__menu-toggle:focus {
	outline: 0;
}

@media (--mq-up-to-small) {
	.site-header__menu-toggle {
		display: block;
	}
}

.site-header__cta {
	color: var(--color-grey);
	display: flex;
	overflow: hidden;
	text-decoration: none;
}

@media (--mq-up-to-small) {
	.site-header__cta {
		float: left;
		width: 50%;
	}
}

.site-header__cta span {
	background-color: var(--color-accent);
	border-bottom: 1px solid black;
	color: white;
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 1.5em;
	padding: 0.1em 0.5em 0.15em;
	text-align: center;
	text-transform: uppercase;
}

@media (--mq-up-to-small) {
	.site-header__cta span {
		font-size: 1rem;
		line-height: 2rem;
		padding: 0 1rem;
		text-align: left;
		height: 2rem;
	}
}

.site-header__cta span:first-child {
	border-left: none;
}

.site-header__cta span:last-child {
	border-right: none;
}

@media screen and (prefers-reduced-motion: reduce) {
	.site-header__cta span {
		animation: none;
	}
}

@media (--mq-from-small) {
	.site-header__cta span {
		animation: color-flicker 2s steps(1, end) infinite normal;
	}
}

@media (--mq-up-to-small) {
	.site-header__cta span:not(:first-child) {
		display: none;
	}
}

.site-header__cta span:nth-child(even) {
	animation-direction: reverse;
	background-color: white;
	color: var(--color-accent);
}

.site-header__navigation {
	border-bottom: 1px solid currentcolor;
	display: flex;
	flex-wrap: nowrap;
	position: relative;
	text-align: left;
}

@media (--mq-up-to-small) {
	.site-header__navigation {
		display: none;
	}

	.site-header__navigation.is-shown {
		display: flex;
	}
}

@media (--mq-from-small) {
	.site-header__navigation {
		border-bottom: none;
		display: block;
		font-size: 1.125rem;
		text-align: center;
	}
}

@media (--mq-up-to-small) {
	.site-header__primary-navigation,
	.site-header__subnavigation {
		flex-basis: 50%;
		flex-grow: 1;
		flex-shrink: 0;
	}
}

@media (--mq-from-small) {
	.site-header__primary-navigation,
	.site-header__subnavigation {
		border-bottom: 1px solid currentcolor;
	}
}

@media (--mq-up-to-small) {
	.site-header__subnavigation {
		border-left: 1px solid currentcolor;
	}
}
