.wrapper {
	background-color: var(--color-grey);
	min-height: 100vh;
}

@media (min-width: 1702px) {
	.wrapper {
		border-right: 1px solid currentcolor;
		border-left: 1px solid currentcolor;
		margin-right: auto;
		margin-left: auto;
		width: 1700px;
	}
}

body.is-showing-popup .wrapper,
body.is-showing-overlay .wrapper {
	filter: blur(1.5rem);
}
