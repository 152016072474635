.board-member {
	display: block;
	width: 100%;
}

@media (--mq-from-xsmall) {
	.board-member {
		display: flex;
	}
}

.board-member:not(:last-child) {
	margin-bottom: 2.5rem;
}

.board-member__picture {
	border: 1px solid currentColor;
}

@media (--mq-up-to-xsmall) {
	.board-member__picture {
		margin-bottom: 1.25rem;
	}
}

@media (--mq-from-xsmall) {
	.board-member__picture {
		margin-right: 1.25rem;
	}
}

.board-member__picture,
.board-member__picture img {
	width: 130px;
	height: 130px;
}

@media (--mq-from-small) and (--mq-up-to-medium) {
	.board-member__picture,
	.board-member__picture img {
		width: 80px;
		height: 80px;
	}
}

.board-member__content {
	font-size: 1rem;
}

@media (--mq-from-small) {
	.board-member__content {
		font-size: 1.125rem;
	}
}

@media (--mq-from-medium) {
	.board-member__content {
		font-size: 1.375rem;
	}
}

.board-member__content p {
	line-height: 1.5;
	margin-top: 0;
}

.board-member__content p:last-child {
	margin-bottom: 0;
}
