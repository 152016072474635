.lineup {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0 0.5rem 0.5rem;
	justify-content: space-between;
}

@media (--mq-from-small) {
	.lineup {
		padding-right: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
	}
}

@supports (display: grid) {
	.lineup {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: repeat(2, 1fr);
	}

	@media (--mq-from-small) {
		.lineup {
			grid-gap: 2rem;
			grid-template-columns: repeat(3, 1fr);
		}
	}

	@media (--mq-from-medium) {
		.lineup {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	@media (--mq-from-large) {
		.lineup {
			grid-template-columns: repeat(5, 1fr);
		}
	}
}

.page-content__row .lineup {
	padding: 0;
	width: 100%;
}

.lineup__item {
	box-sizing: border-box;
	flex-basis: auto;
	flex-grow: 0;
	flex-shrink: 1;
	padding: 0;
	width: 48%;
}

@media (--mq-from-small) {
	.lineup__item {
		padding: 1rem 0;
		width: 31.3334%;
	}
}

@media (--mq-from-medium) {
	.lineup__item {
		width: 23%;
	}
}

@media (--mq-from-large) {
	.lineup__item {
		width: 18%;
	}
}

@supports (display: grid) {
	.lineup__item {
		padding-top: 0;
		width: auto;
	}
}

.lineup__item a {
	color: var(--color-black);
	text-decoration: none;
}

.lineup__poster-image {
	background-color: var(--color-accent);
	display: block;
	margin-bottom: 0.625rem;
	overflow: hidden;
	position: relative;
	width: 100%;
}

@media (--mq-from-xsmall) {
	.lineup__poster-image {
		margin-bottom: 0.8125rem;
	}
}

.lineup__poster-image video,
.lineup__poster-image img {
	display: block;
	max-width: 100%;
}

.lineup__poster-image::before {
	border-right: 1px solid var(--color-grey);
	content: "";
	position: absolute;
	top: -1px;
	right: 100%;
	bottom: -1px;
	transform: translateX(1px) skew(-10deg);
	transform-origin: bottom;
	width: 100%;
}

.lineup__poster-image::after {
	border-left: 1px solid var(--color-grey);
	content: "";
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: 100%;
	transform: translateX(-1px) skew(-10deg);
	transform-origin: top;
	width: 100%;
}

.lineup__poster-image:hover::before,
.lineup__poster-image:hover::after {
	background-color: var(--color-red);
	border-color: var(--color-red);
}

.lineup__item--placeholder .lineup__poster-image::before,
.lineup__item--placeholder .lineup__poster-image::after {
	display: none;
}

.lineup__meta {
	border-bottom: 1px solid currentcolor;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0.625rem;
	padding-bottom: 0.8125rem;
	align-items: flex-start;
}

@media (--mq-from-small) {
	.lineup__meta {
		margin-bottom: 0.8125rem;
		padding-bottom: 0.8125rem;
		min-height: 2.5rem;
	}
}

@media (--mq-from-wide) {
	.lineup__meta {
		flex-wrap: nowrap;
		min-height: auto;
	}
}

.lineup__name {
	display: block;
	flex-basis: 100%;
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 0.9rem;
	font-weight: 600;
	text-transform: uppercase;
}

@media (--mq-from-wide) {
	.lineup__name {
		flex-basis: 0;
		font-size: 1rem;
	}
}

.lineup__city {
	flex-basis: 0;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 0.8125rem;
	white-space: nowrap;
}

.lineup__intro {
	display: block;
	font-size: 0.8125rem;
	line-height: 1.35;
}

.lineup__intro p:first-child {
	margin-top: 0;
}

.lineup__intro p:last-child {
	margin-bottom: 0;
}

.lineup__intro-and-presented-by {
	display: flex;
	flex-wrap: wrap;
	gap: 0.25rem;
	justify-content: space-between;
}

.lineup__presented-by {
	display: inline-block;
	font-size: 0.8125rem;
	line-height: 1.35;
	padding: 0 3px;

	/* color */
	background-color: black;
	color: white;
	mix-blend-mode: multiply;
}

.lineup__item a:hover .lineup__presented-by {
	background-color: white;
	color: black;
	mix-blend-mode: screen;
}
