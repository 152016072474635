.patrons {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	list-style: none;
	margin: 0;
	padding: 0 0.5rem 0.5rem;
}

@media (--mq-from-small) {
	.patrons {
		grid-gap: 2rem;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		padding-right: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
	}
}

@media (--mq-from-medium) {
	.patrons {
		grid-template-columns: repeat(6, minmax(0, 1fr));
	}
}

@media (--mq-from-wide) {
	.patrons {
		grid-template-columns: repeat(7, minmax(0, 1fr));
	}
}

.page-content__row .patrons {
	padding: 0;
	width: 100%;
}

.patron {
	box-sizing: border-box;
	padding: 0;
	width: 100%;
}

.patron__image-container {
	display: block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	width: 100%;
}

.patron__image {
	display: block;
	width: 100%;
}
