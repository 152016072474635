body {
	background-color: var(--t-background-color);
	background-position: center;
	background-size: cover;
	color: black;
}

@media (--mq-up-to-small) {
	body {
		padding-top: 4.125rem;
	}
}

@media (max-width: 1699px) {
	body {
		background-image: none !important;
	}
}

body.is-showing-popup,
body.is-showing-overlay {
	filter: none !important;
	overflow: hidden;
}
