.hotspots-filter {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding: 0 0.5rem 1rem;
}

@media (--mq-from-small) {
	.hotspots-filter {
		margin: -1rem 0 0;
		padding: 0 1rem 1rem;
	}
}

.hotspots-filter__filter {
	box-sizing: border-box;
	flex-basis: 50%;
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 1rem;
	letter-spacing: 0.025em;
	padding: 0.5rem;
	text-transform: uppercase;
}

@media (--mq-from-small) {
	.hotspots-filter__filter {
		font-size: 1.125rem;
		padding: 1rem;
	}
}

@media (--mq-from-wide) {
	.hotspots-filter__filter {
		flex-basis: auto;
	}
}

.hotspots-filter__filter::before {
	background-color: var(--color-hotspots-filter);
	border-radius: 50%;
	content: "";
	display: inline-block;
	margin-right: 0.25rem;
	vertical-align: top;
	width: 0.875rem;
	height: 0.875rem;
}

@media (--mq-up-to-small) {
	.hotspots-filter__filter::before {
		position: relative;
		top: 3px;
	}
}

@media (--mq-from-small) {
	.hotspots-filter__filter::before {
		width: 1.375rem;
		height: 1.375rem;
	}
}

.hotspots-filter__filter[data-type="fun"]::before {
	background-color: var(--color-hotspots-filter-fun);
}

.hotspots-filter__filter[data-type="food"]::before {
	background-color: var(--color-hotspots-filter-food);
}

.hotspots-filter__filter[data-type="drinks"]::before {
	background-color: var(--color-hotspots-filter-drinks);
}

.hotspots-filter__filter[data-type="hotel"]::before {
	background-color: var(--color-hotspots-filter-hotel);
}

.hotspots-filter__filter[data-type="sight"]::before {
	background-color: var(--color-hotspots-filter-sight);
}

.hotspots-filter__filter a {
	text-decoration: none;
}

.hotspots-filter__filter--active a {
	text-decoration: underline;
}
