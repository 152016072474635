.popup {
	background-color: rgb(0 0 0 / 0.5);
	cursor: auto;
	display: none;
	opacity: 0;
	bottom: 0;
	left: 0;
	min-height: 100%;
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	pointer-events: none;
	position: fixed;
	top: 0;
	width: 100%;
}

@media (--mq-from-small) {
	.popup {
		background-color: rgb(0 0 0 / 0.15);
	}
}

.popup[data-state="opening"],
.popup[data-state="open"],
.popup[data-state="closing"] {
	display: block;
	opacity: 1;
	pointer-events: auto;
	position: fixed;
	z-index: var(--z-index-popup);
}

.popup a:focus,
.popup a:hover {
	color: currentColor !important;
	text-decoration: none;
}

.popup__modal {
	background-color: white;
	color: black;
	margin: 1rem auto;
	overflow: hidden;
	position: relative;
	width: calc(100% - 2rem);
	z-index: var(--z-index-popup-modal);
}

@media (--mq-from-small) {
	.popup__modal {
		margin-top: 7rem;
		margin-bottom: 7rem;
		width: 92.5%;
		max-width: 1280px;
	}
}

@media (--mq-from-medium) {
	.popup__modal {
		width: 82.5%;
	}
}

.popup__close {
	appearance: none;
	background-color: transparent;
	border: 0 solid transparent;
	border-radius: 0;
	color: currentcolor;
	position: absolute;
	text-decoration: none;
	top: 0;
	right: 0;
	width: 3rem;
	height: 3rem;
}

@media (--mq-up-to-small) {
	.popup__close {
		position: fixed;
		top: 1rem;
		right: 1rem;
	}
}

@media (--mq-from-small) {
	.popup__close {
		width: 6rem;
		height: 6rem;
	}
}

.popup__close svg {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 1rem;
	height: 1rem;
}

@media (--mq-from-small) {
	.popup__close svg {
		width: 2rem;
		height: 2rem;
	}
}

.popup__header {
	padding: 1rem;
}

@media (--mq-from-small) {
	.popup__header {
		padding: 2rem;
	}
}

.popup__title {
	font-size: 3.75rem;
	letter-spacing: 1px;
	line-height: 0.85;
	margin-top: -6px; /* Compensate line-height */
	margin-bottom: 0;
	text-transform: uppercase;
	width: 100%;
	/* stylelint-disable-next-line declaration-property-value-keyword-no-deprecated */
	word-break: break-word;
}

@media (--mq-from-small) {
	.popup__title {
		font-size: 7.5rem;
		margin-top: -12px; /* Compensate line-height */
		width: calc(100% - 5rem);
	}
}

@media (--mq-from-large) {
	.popup__title {
		font-size: 10.5rem;
	}
}

.popup__content {
	display: flex;
	padding: 0 2rem 2rem;
}

@media (--mq-up-to-small) {
	.popup__content {
		flex-wrap: wrap;
		padding-right: 1rem;
		padding-bottom: 1rem;
		padding-left: 1rem;
	}
}

.popup__aside {
	flex-basis: 100%;
	flex-grow: 0;
	flex-shrink: 1;
}

@media (--mq-from-small) {
	.popup__aside {
		flex-basis: calc(25% - 2rem);
		margin-left: 2rem;
	}
}

.popup__body {
	flex-basis: 75%;
	flex-grow: 1;
	flex-shrink: 0;
}

@media (--mq-up-to-small) {
	.popup__body {
		flex-basis: 100%;
	}
}

.popup__body p {
	line-height: 1.675;
	margin-top: 0;
}

.popup__background {
	background-color: rgb(0 0 0 / 0.5);
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: var(--z-index-popup-background);
}
