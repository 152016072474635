:root {
	--font-sans: "GT America", "gt-america", "Helvetica Neue", sans-serif;
	--font-serif: "Georgia", serif;
	--color-accent: rgb(227 227 227);
	--color-background: #ffffff;
	--color-black: rgb(0 0 0); /* #000000 */
	--color-foreground: #000000;
	--color-grey: rgb(100 100 100); /* #646464 */
	--color-purple: rgb(210 180 255); /* #D2B4FF */
	--color-red: rgb(230 50 35); /* #E63223 */
	--color-silver: rgb(235 235 235); /* #EBEBEB */
	--color-white: rgb(255 255 255); /* #FFFFFF */
	--color-body-background: var(--color-grey);
	--color-newsletter-submit-bg: var(--color-grey);
	--color-newsletter-submit-fg: var(--color-black);
	--color-flicker-bg-startend: var(--color-black);
	--color-flicker-fg-startend: currentcolor;
	--color-flicker-bg-middle: transparent;
	--color-flicker-fg-middle: var(--color-black);
	--color-video-label: var(--color-white);
	--color-ticket-fg: var(--color-black);
	--color-ticket-bg: var(--color-white);
	--color-ticket-inner-border: var(--color-black);
	--color-ticket-inverted-fg: var(--color-white);
	--color-ticket-inverted-bg: var(--color-grey);
	--color-ticket-inner-border-inverted: var(--color-white);
	--color-hotspots-filter: #000000;
	--color-hotspots-filter-fun: rgb(190 190 190); /* #BEBEBE */
	--color-hotspots-filter-food: var(--color-purple);
	--color-hotspots-filter-drinks: var(--color-red);
	--color-hotspots-filter-hotel: var(--color-black);
	--color-hotspots-filter-sight: var(--color-grey);
	--color-schedule-line-solid: var(--color-black);
	--color-schedule-line-muted: rgb(128 128 128);
	--color-schedule-item-bg: var(--color-black);
	--color-schedule-item-fg: var(--color-white);
	--color-schedule-item-hover-bg: var(--color-black);
	--color-schedule-item-hover-fg: var(--color-white);
	--color-schedule-item-inverted-bg: var(--color-white);
	--color-schedule-item-inverted-fg: var(--color-black);
	--color-schedule-item-inverted-hover-bg: var(--color-white);
	--color-schedule-item-inverted-hover-fg: var(--color-black);
	--z-index-overlay: 10000;
	--z-index-popup: 10100;
	--z-index-popup-background: 10101;
	--z-index-popup-modal: 10102;
}
