:root {
	--five-minutes: 0.9375rem;
	--thirty-minutes: calc(var(--five-minutes) * 6);
}

@media (--mq-from-small) {
	.schedule {
		background-image: repeating-linear-gradient(transparent 0, transparent var(--thirty-minutes), var(--color-schedule-line-solid) calc(var(--thirty-minutes) + 1px), transparent calc(var(--thirty-minutes) + 2px), transparent calc(2 * var(--thirty-minutes) - 1px), var(--color-schedule-line-muted) calc(2 * var(--thirty-minutes)));
		display: flex;
		margin: 0 auto var(--thirty-minutes);
		padding-top: var(--thirty-minutes);
	}
}

.schedule__legend {
	display: flex;
	flex-direction: column;
	position: relative;
	top: calc(-1 * var(--thirty-minutes));
	width: 18rem;
}

@media (--mq-up-to-small) {
	.schedule__legend {
		display: none;
	}
}

.schedule__legend__time {
	box-sizing: border-box;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 13rem;
	padding: 1rem;
	height: calc(2 * var(--thirty-minutes));
}

@media (--mq-from-small) {
	.schedule__grid {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		width: 100%;
		height: calc(2 * var(--thirty-minutes) + 1px);
	}
}

@media (--mq-from-small) {
	.schedule__stage {
		display: grid;
		grid-gap: 0;
		grid-auto-rows: var(--five-minutes);
		grid-auto-columns: 100%;
		position: relative;
		width: 30%;
		height: 100%;
	}
}

@media (--mq-from-medium) {
	.schedule__stage {
		width: 31%;
	}
}

@media (--mq-from-wide) {
	.schedule__stage {
		width: 24%;
	}
}

.schedule__item {
	background-color: var(--color-schedule-item-bg);
	color: var(--color-schedule-item-fg);
	display: block;
	line-height: 1.25;
	margin-bottom: 1px;
	overflow: hidden;
	padding: 0.9rem 115px 1rem 1rem;
	position: relative;
	text-decoration: none;
}

.schedule__item[data-inverted-colors] {
	background-color: var(--color-schedule-item-inverted-bg);
	color: var(--color-schedule-item-inverted-fg);
}

@media (--mq-from-small) {
	.schedule__item {
		margin: 0;
		padding: 0.9rem 4rem 1rem 1rem;
	}
}

.schedule__item:last-child {
	margin-bottom: 0;
}

.schedule__item.schedule__item--has-bar {
	padding-bottom: 3.0625rem !important;
}

.schedule__item.schedule__item--has-bar::after {
	background-color: var(--color-black);
	box-sizing: border-box;
	color: var(--color-white);
	content: attr(data-bar);
	display: block;
	font-size: 0.875rem;
	line-height: calc(var(--five-minutes) * 2);
	overflow: hidden;
	padding: 0 1rem;
	position: absolute;
	text-overflow: ellipsis;
	bottom: 0;
	left: 0;
	white-space: nowrap;
	width: 100%;
	height: calc(var(--five-minutes) * 2);
}

@media (--mq-from-small) {
	a.schedule__item:hover {
		background-color: var(--color-schedule-item-hover-bg);
		color: var(--color-schedule-item-hover-fg);
	}

	a.schedule__item[data-inverted-colors]:hover {
		background-color: var(--color-schedule-item-inverted-hover-bg);
		color: var(--color-schedule-item-inverted-hover-fg);
	}
}

.schedule__item--heading {
	background-color: var(--color-grey);
	box-sizing: border-box;
	color: black;
	font-size: 1rem;
	line-height: 1.75;
	padding: 0 1rem;
	text-align: center;
	text-transform: uppercase;
	height: 2rem;
}

@media (--mq-up-to-small) {
	.schedule__item--heading {
		border-top: 1px solid black;
		border-bottom: 1px solid black;
		display: flex;
		margin-bottom: 0;
		justify-content: space-between;
		position: sticky;
		top: 65px;
		z-index: 2;
	}
}

@media (--mq-up-to-small) {
	.schedule__item--heading:not(*:first-of-type) {
		margin-top: 2rem;
	}
}

@media (--mq-from-small) {
	.schedule__item--heading {
		font-size: 1.25rem;
		line-height: var(--thirty-minutes);
		padding: 0 1rem;
		position: absolute;
		text-decoration: underline;
		top: calc(-1 * var(--thirty-minutes) - 1px);
		right: 0%;
		left: 0%;
		height: var(--thirty-minutes);
	}

	.schedule__item--heading span:first-child {
		display: none;
	}
}

.schedule__item--heading:hover {
	background-color: white;
}

@media (--mq-from-small) {
	.schedule__item__start {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}
}

@media (--mq-up-to-small) {
	.schedule__item__start::after {
		content: " - ";
	}
}

@media (--mq-from-small) {
	.schedule__item__end {
		position: absolute;
		right: 1rem;
		bottom: 1rem;
	}
}

@media (--mq-from-small) {
	.schedule__item--has-bar .schedule__item__end {
		bottom: calc(var(--five-minutes) * 2 + 1rem);
	}
}

.schedule__item__speaker {
	display: block;
	font-weight: 700;
	margin-bottom: 0.5rem;
}

.schedule__item__speaker small {
	font-weight: 400;
	letter-spacing: 0.025em;
	opacity: 0.35;
	text-transform: uppercase;
}

@media (--mq-up-to-small) {
	.schedule__item__speaker small {
		display: none;
	}
}

.schedule__item__title {
	display: block;
	font-size: 0.875rem;
	margin-bottom: 0.5rem;
}

*.schedule__item__title:last-of-type {
	margin-bottom: 0;
}

.schedule__item__curator,
.schedule__item__extra_label {
	display: block;
	font-size: 0.875rem;
	opacity: 0.85;
}

.schedule__item__tooltip {
	display: none;
	pointer-events: none;
	max-width: 300px;
	z-index: 999999;
}

.schedule__item__tooltip.is-resolved {
	box-shadow: 0.5rem 0.5rem 0 #000000;
	visibility: hidden;
}

@media (--mq-from-medium) {
	.schedule__item__tooltip.is-resolved {
		display: block;
	}
}

.schedule__item__tooltip img,
.schedule__item__tooltip video {
	display: block;
	max-width: 100%;
}

.schedule__item__thumbnail {
	position: absolute;
	top: 0;
	right: 0;
	width: 100px;
	height: auto;
}

@media (--mq-from-small) {
	.schedule__item__thumbnail {
		display: none;
	}
}

.schedule__item__thumbnail img,
.schedule__item__thumbnail video {
	width: 100%;
	height: auto;
}

@media (--mq-from-small) {
	.schedule__grid {
		--data-hours: 1;

		height: calc((var(--data-hours) * 2) * var(--thirty-minutes) + 1px);
	}

	/**
	 * Duration in five minute intervals
	 */
	.schedule__item {
		--data-duration: 5;

		grid-row-end: span calc(var(--data-duration) / 5);
	}

	.schedule__item[data-bar] {
		grid-row-end: span calc((var(--data-duration) + 10) / 5);
	}

	/**
	 * Start in HH:MM notation relative to start of schedule
	 */
	.schedule__item {
		--data-start: 0;

		grid-row-start: calc((var(--data-start) / 5) + 1);
	}
}
