.navigation {
	display: flex;
	flex-direction: column;
	list-style: none;
	margin: 0;
	padding: 0;
	justify-content: center;
	align-items: stretch;
}

@media (min-width: 768px) {
	.navigation {
		flex-direction: row;
		align-items: flex-start;
	}

	.site-header__primary-navigation .navigation {
		align-items: stretch;
	}
}

@media (max-width: 767px) {
	.program__navigation .navigation {
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.navigation--small {
	font-size: 0.8125rem;
}

@media (min-width: 768px) {
	.navigation--centered {
		justify-content: center;
		align-items: center;
	}
}

.navigation--centered .navigation__item {
	flex-basis: auto;
}

@media (min-width: 768px) {
	.navigation--centered .navigation__item {
		flex-grow: 0;
	}
}

@media (min-width: 768px) {
	.navigation--right {
		justify-content: flex-end;
		align-items: center;
	}
}

.navigation--right .navigation__item {
	flex-basis: auto;
}

@media (min-width: 768px) {
	.navigation--right .navigation__item {
		flex-grow: 0;
	}
}

.navigation--borderless .navigation__item {
	border-bottom-color: transparent;
}

@media (min-width: 768px) {
	.navigation--borderless .navigation__item {
		border-left-color: transparent;
	}
}

@media (min-width: 768px) {
	.navigation--borderless .navigation__item.current-menu-item::after,
	.navigation--borderless .navigation__item.current-menu-ancestor::after {
		display: none;
	}
}

.navigation__item {
	border-bottom: 1px solid var(--t-color);
	flex-basis: 0;
	flex-grow: 1;
	flex-shrink: 0;
}

@media (min-width: 768px) {
	.navigation__item {
		border-bottom: 0;
		border-left: 1px solid var(--t-color);
	}

	.site-header__primary-navigation .navigation__item {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.navigation__item:first-child {
	border-left: none;
}

.navigation__item:last-child {
	border-bottom: none;
}

.navigation__item.current-menu-item,
.navigation__item.current-page-ancestor {
	position: relative;
}

.navigation__item.current-menu-item a,
.navigation__item.current-page-ancestor a {
	color: var(--color-black);
}

.navigation__item.current-menu-item::after,
.navigation__item.current-page-ancestor::after {
	background-color: var(--color-grey);
	content: "";
	display: block;
	position: absolute;
}

@media (max-width: 767px) {
	.navigation__item.current-menu-item::after,
	.navigation__item.current-page-ancestor::after {
		top: 0;
		right: -2px;
		width: 3px;
		height: 100%;
	}
}

@media (min-width: 768px) {
	.navigation__item.current-menu-item::after,
	.navigation__item.current-page-ancestor::after {
		bottom: -2px;
		left: 0;
		width: 100%;
		height: 3px;
	}
}

@media (max-width: 767px) {
	.program__navigation .navigation__item {
		border-bottom: 1px solid var(--t-color);
		box-sizing: border-box;
		flex-basis: 50%;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

@media (max-width: 767px) {
	.program__navigation .navigation__item:nth-child(2n + 1) {
		border-right: 1px solid var(--t-color);
	}
}

.navigation__item a {
	color: currentcolor;
	display: block;
	padding: 0.625em 1em 0.75em;
	text-decoration: none;
}

@media (min-width: 768px) {
	.navigation__item a {
		padding-right: 0.625em;
		padding-left: 0.625em;
	}
}

@media (min-width: 1024px) {
	.navigation__item a {
		padding-right: 1em;
		padding-left: 1em;
	}
}

@media (max-width: 767px) {
	.program__navigation .navigation__item a {
		line-height: 2rem;
		padding-top: 0;
		padding-bottom: 0;
		height: 2rem;
	}
}
